$primary-color: #000A28;
$secondary-color: #00E220;
$azul-marinho: #000E39;
$azul-claro: #21B5EA;
$amarelo:#FFCE00;
$gelo:#E6EFFF;
$cinza-padrao:#737373;

.background-primary {
  background-color: $primary-color;
}

.background-secondary {
  background-color: $secondary-color;
}

.background-amarelo {
  background-color: $amarelo;
}

.background-third {
  background-color: $azul-marinho;
}

.background-soft {
  background: $gelo;
}



.background-quarty {
  background-color: $azul-claro;
}

.text-white {
  color: $gelo;
  &.child * {
    color: inherit;
  }
}

.color-primary {
  color: $primary-color;
  &.child * {
    color: inherit;
  }
}

.color-cinza {
  color:$cinza-padrao;
  &.child * {
    color: inherit;
  }
}

.color-secondary {
  color: $secondary-color;
  &.child * {
    color: inherit;
  }
}

.color-azul-claro {
  color: $azul-claro;
}

.color-amarelo {
  color: $amarelo;
}