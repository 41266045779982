img {
  display: block;
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}


.overflow-hidden {
  overflow: hidden;
}

.effect-photo {
  img {
    transition: all ease-in-out 300ms;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}