.button {
  display: inline-block;
  padding: 1.5rem 2.5rem;
  color: $secondary-color; /* Cor do texto (verde) */
  border: 3px solid $secondary-color; /* Borda verde */
  background-color: transparent;
  border-radius: 0 3.125rem 0 0; /* Canto arredondado no canto superior direito */
  text-align: center;
  font-size: 1rem; /* Ajuste o tamanho da fonte conforme necessário */
  text-decoration: none;
  transition: all 0.3s ease-in-out; /* Animação suave ao passar o mouse */
}

.button:hover {
  background-color: $secondary-color; /* Fundo verde ao passar o mouse */
  color: #000000; /* Texto preto ao passar o mouse */
}


.button-secondary {
  background-color: $secondary-color; /* Fundo verde ao passar o mouse */
  color: #000000 !important; /* Texto preto ao passar o mouse */
  &:hover {
    background-color: #01ae16 !important; /* Fundo verde ao passar o mouse */
    border-color: #01ae16 !important; /* Fundo verde ao passar o mouse */
  }
}



.button {
  &.button-primary {
    transition: all ease-in-out 300ms;
    background-color: $primary-color; /* Fundo verde ao passar o mouse */
    color: $secondary-color; /* Texto preto ao passar o mouse */
    border-color: $primary-color; /* Texto preto ao passar o mouse */
    &:hover {
      background-color: #000413; /* Fundo verde ao passar o mouse */
      border-color: #000413; /* Fundo verde ao passar o mouse */
    }
  }

  &.button-third {
    font-weight: 600;
    background-color: $azul-claro; /* Fundo verde ao passar o mouse */
    color: $azul-marinho; /* Texto preto ao passar o mouse */
    border-color: $azul-claro; /* Texto preto ao passar o mouse */
    &:hover {
      background-color: #0b97c8; /* Fundo verde ao passar o mouse */
      border-color: #0b97c8; /* Fundo verde ao passar o mouse */
    }
  }


  &.button-quarter {
    font-weight: 600;
    background-color: $amarelo; /* Fundo verde ao passar o mouse */
    color: $azul-marinho; /* Texto preto ao passar o mouse */
    border-color: $amarelo; /* Texto preto ao passar o mouse */
    &:hover {
      background-color: #b5930a; /* Fundo verde ao passar o mouse */
      border-color: #b5930a; /* Fundo verde ao passar o mouse */
    }
  }

  &.medium {
    padding: 1.25rem 2rem;
    font-size: 0.875rem;
  }

  &.small {
    padding: 0.75rem 2rem 0.75rem 1.5rem;
    font-size: 0.875rem;
  }

  &.tag {
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    font-size: 0.75rem;
  }

}

@media (max-width: 1420px) {
  .button {
    border: 2px solid $secondary-color; /* Borda verde */
  }
}