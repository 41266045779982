.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.col {
  flex: 1;
}

.min-fit-content {
  width: fit-content;
}


.flex-col-3 {
  display: flex;
  flex-wrap: wrap;

  .col-flex {
    max-width: calc(33%);
  }

  &.gap-24 {
    .col-flex {
      max-width: calc(33% - 0.75rem);
    }
  }
}


/* GRID */
.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal-width columns */
}

.grid-col-3 {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr); /* 4 equal-width columns */
}

@media (max-width: 768px) {
  .grid-col-mb-2 {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr); /* 4 equal-width columns */
  }
}