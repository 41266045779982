.nav-menu {
  display: flex;
  gap: 2.5rem;
  a {
    color: $gelo;
    font-size: 1rem;
    text-decoration: none;
    transition: all ease-in-out 300ms;
    &:hover {
      color:$secondary-color
    }
  }

  .current_page_item {
    a {
      color:$secondary-color
    }
  }
}

.nav-menu-footer {
  a {
    color: $gelo;
    font-size: 1rem;
    text-decoration: none;
    transition: all ease-in-out 300ms;
    &:hover {
      color:$secondary-color
    }
  }
  li + li {
    margin-top: 0.5rem;
  }
}