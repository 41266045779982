.row-form {
  input[type=text], input[type=email], input[type=tel], select {
    width: 100%;
    height: 4rem;
    padding: 1rem 1.5rem;
    appearance: none;
    color: var(--Cinza-Padro, #737373);

    font-family: Montserrat, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.56px;
    background-color: transparent;
    border: 1px solid var(--Cinza-Claro, #AEC8D1);
    border-radius: 0px 1.5rem 0px 0px;
  }

  select {
    background-image: url("../images/icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
  }

  textarea {
    width: 100%;
    padding: 1rem 1.5rem;
    appearance: none;
    color: var(--Cinza-Padro, #737373);

    font-family: Montserrat, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.56px;
    background-color: transparent;
    border: 1px solid var(--Cinza-Claro, #AEC8D1);
    border-radius: 0px 1.5rem 0px 0px;
  }
}

.form-checkbox {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  input[type=checkbox] {
    appearance: none;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 4px;
    border: 1px solid var(--Azul-Claro, #21B5EA);
    transition: all ease-in-out 300ms;
    &:checked {
      background-color:#21B5EA;
    }
  }

  label {
    color: var(--Cinza-Padro, #737373);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.56px;
    a {
      color: var(--Azul-Claro, #21B5EA);
      text-decoration: underline;
      font-size: 0.875rem;
    }
  }
}