.logo-w-182 {
  max-width: 11.375rem;
  height: auto;
}

header {
  z-index: 999;
}

.wrap {
  margin-top: 5rem;
}


.topo-slider {
  position: relative;

  &:before {
    position: absolute;
    width: 25.18rem;
    z-index: 9;
    height: 41.68rem;
    right: 0px;
    bottom: 0px;
    content: "";
    background: url("../images/effect-1.svg");
    background-size: cover;
  }

  .slide:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000A28 0%, rgba(0, 10, 40, 0) 80%);
  }

  .abs {
    z-index: 3;
  }

  .slide {
    h2 {
      line-height: 1.1875;
    }

    p {
      line-height: 1.3333;
    }
  }

  .slide:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-90deg, #000A28 0%, rgba(0, 10, 40, 0) 80%);
  }
}

.max-614 {
  max-width: 39.375rem;
}

.max-1198 {
  max-width: 74.875rem;
}

.poppins {
  font-family: Poppins, sans-serif;
}

.cards {
  position: relative;

  h2 {
    max-width: 53.52rem;
    margin: 0 auto;
  }

}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
  /* Expande a largura até o tamanho total do texto */
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: black;
  }
}

.typed::after {
  content: "|";
  font-size: 150%;
  line-height: 30px;
  animation: blink .75s step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent
  }
  50% {
    color: inherit
  }
}

.border-special {
  border-radius: 0px 5rem 0px 0px;
}

body {
  background-color: $primary-color;
}


.aspect-ratio-378-240 {
  aspect-ratio: 378 / 240;
}

header {
  transition: all ease-in-out 600ms;
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.35);
}

.top {
  header {
    box-shadow: none;
  }
}

.logo-footer {
  max-width: 14.625rem;
  height: auto;
}

.logo-dotend {
  max-width: 2.6875rem;
  height: auto;
}

.social-media {
  svg {
    max-height: 1.5rem;
    width: auto;
  }
}

.cards {
  .container {
    position: relative;
    z-index: 9;
  }
}

@media (max-width: 1420px) {
  .topo-slider:before {
    max-height: 70vh;
    max-width: 15vw;
  }

  .cards .appearing-1 {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      aspect-ratio: 260 / 310;
      max-width: 14.31rem;
    }

    height: auto;
  }

  .cards .appearing-2 {
    bottom: 1px;
    width: 100%;
    display: flex;
    height: calc(50%);
    align-items: flex-end;

    img {
      aspect-ratio: 278 / 320;
      max-width: 14.31rem;
    }
  }

  .cards {
    .item {
      p {
        font-size: 1rem;
      }
    }
  }
}

footer .col {
  min-width: fit-content;
}

.max-955 {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.max-1198 {

}

.cards-horizontais {
  .item + .item {
    margin-top: 1.5rem;
  }

  .item {
    border-radius: 0px 5rem 0px 0px;

    .context {
      padding-right: 4rem;
    }
  }

  .item:nth-child(even) {
    border-radius: 5rem 0px 0px 0px;

    .context {
      padding-left: 4rem;
      padding-right: 2.5rem;
    }

    .flex {
      flex-direction: row-reverse;
    }
  }
}

.cards-tema-2 {
  .item {
    border-radius: 0px 5rem 0px 0px;

    .thumbnail {
      border-radius: 0px 5rem 0px 0px;

      img {
        border-radius: 0px 5rem 0px 0px;
      }
    }
  }
}

.item-effect-thumbnail {
  img {
    transition: all ease-in-out 300ms;
  }

  .thumbnail {
    overflow: hidden;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.max-469 {
  max-width: 29.3125rem;
  height: auto;
}


.min-469 {
  min-width: 29.3125rem;
}

.max-462 {
  max-width: 28.875rem;
  height: auto;
}

.top-video {
  position: relative;
  height: calc(100vh - 5rem);

  .video-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 5rem);
    object-fit: cover;
  }

  .image-video {
    cursor: pointer;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .play {
    position: absolute;
    max-width: 10rem;
    top: calc(50% - 3.5rem);
    left: calc(50% - 5rem);

    svg {
      width: 10rem;
      height: 7rem;
    }
  }
}


.valores .item {
  border-radius: 0px 3rem 0px 0px;
}

form {
  position: relative;
  .loading {
    position: absolute;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    background-color: rgba(255, 255, 255, 0.6);
    display: none;
    z-index: 2;
    left: -20px;
    top: -20px;
    justify-content: center;
    align-items: center;
    gap:16px;
  }

  /* Animação de rotação */
  &.loading {
    .loading {
      display: flex;
    }
  }
}


.linkedin {
  border-top-left-radius: 3.0625rem;
  padding: 1.25rem 1rem 1.25rem 2.25rem;
  background-color: $azul-marinho;
}

.gap-136 {
  gap: 8.5rem;
}

.tabs {
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    border-radius: 0px 4rem 0px 0px;
    padding: 1rem 2rem 1rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: $gelo;
    transition: all ease-in-out 300ms;

    &:hover {
      background-color: $azul-marinho;
    }

    &.act {
      background-color: $secondary-color;
      color: $primary-color;
    }
  }
}

.tabs {
  .servico {
    display: none;

    &.act {
      display: block;
    }

    li {
      display: flex;
      gap: 1rem;
      align-items: center;

      &:before {
        display: block;
        min-width: 1.25rem;
        max-width: 1.25rem;
        height: 1.45rem;
        content: "";
        background: url("../images/sd.svg") no-repeat;
        background-size: contain;
      }
    }

    li + li {
      margin-top: 1.5rem;
    }
  }
}

.cards-4 {
  li {
    color: #737373;
    display: flex;
    gap: 1rem;
    align-items: center;

    &:before {
      display: block;
      min-width: 1.25rem;
      max-width: 1.25rem;
      height: 1.45rem;
      content: "";
      background: url("../images/sd.svg") no-repeat;
      background-size: contain;
    }
  }
}

.servico {
  .col:last-child {
    flex: 1.1;
  }
}

.servico {
  .abs {
    position: absolute;
    padding: 1.25rem 2rem 1.25rem 2.25rem;
    background-color: $primary-color;
    border-radius: 0 4rem 0px 0px;

    span {
      color: white;
      font-size: 12px;
      font-style: normal;
    }

    h3 {
      color: $azul-claro;
      font-weight: 500;
    }
  }
}

.w-40 {
  width: 2.5rem;
}

.border {
  border: 2px solid $primary-color;
}

.item-effect {
  transition: all ease-in-out 300ms;

  &:hover {
    border-bottom-left-radius: 3.5rem;
  }
}

.background-white {
  background-color: white;
}

.tag-s {
  padding: 1.25rem 2rem 1.25rem 2.25rem;
  border-top-right-radius: 4rem;
  background-color: $azul-claro;
}

.max-955 {
  max-width: 59.68rem;
}

.gap-112 {
  gap: 7rem;
}

.context-single {
  font-size: 0.875rem;
  color: $cinza-padrao;

  p {
    color: $cinza-padrao;
  }

  ul {
    list-style: disc;
    padding-left: 26px;
  }
  li {
    color: $cinza-padrao;
  }

  ol {
    padding-left: 26px;
    list-style: decimal;
  }


  h2, h3, h4, h5, h6 {
    color: $azul-marinho;

    * {
      color: $azul-marinho;
    }
  }


  h2 {
    font-size: 2rem;

    * {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.85rem;

    * {
      font-size: 1.85rem;
    }
  }

  h4 {
    font-size: 1.65rem;

    * {
      font-size: 1.65rem;
    }
  }

  h5 {
    font-size: 1.45rem;

    * {
      font-size: 1.45rem;
    }
  }

  h6 {
    font-size: 1.3rem;

    * {
      font-size: 1.3rem;
    }
  }
}

.max-336 {
  max-width: 21rem;
}

.max-954 {
  max-width: 59.625rem;
}

.newsletter {
  input[type=email] {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: 1px solid #000A28;
    padding: 20px 24px;
    border-top-right-radius: 1.5rem;
    appearance: none;
    color: var(--Azul-Marinho, #000A28);
    font-family: Montserrat, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    height: 3.75rem;
    font-weight: 400;
    letter-spacing: 0.56px;

    &::placeholder {
      color: var(--Azul-Marinho, #000A28);
    }
  }

  input[type=checkbox] {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 4px;
    appearance: none;
    border: 1px solid #000A28;
    background-color: transparent;
    transition: all ease-in-out 300ms;
  }

  button {
    cursor: pointer;
  }
}

.min-width-fit {
  min-width: fit-content;
}

.max-757 {
  max-width: 45.31rem;
}

.max-531 {
  max-width: 33.18rem;
}

.parallax-asset {
  min-height: 100%;
}


.whatsapp-fixed {
  position: fixed;
  bottom: 40px;
  right: 62px;
  z-index: 9;

  svg {
    max-width: 32px;
    height: auto;

    path {
      fill: white;
    }
  }
}

.btn-whatsapp {
  display: flex;
  justify-content: center;
  background-color: #30bf39;
  color: #fff;
  border-radius: 100%;
  transition: background-color .5s;
  width: 60px !important;
  height: 60px !important;
  line-height: 70px;
  position: relative !important;
  transform: none !important;
  z-index: 9;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07),
  0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07),
  0 32px 64px rgba(0, 0, 0, 0.07);
}

.btn-whatsapp:hover {
  background-color: #53ca5b;
}

.whatsapp-fixed a.video-vemo-icon.btn-whatsapp i {
  font-size: 32px;
  color: #fff;
  animation: sm-shake-animation linear 1.5s infinite;
  animation-delay: 3s;
}

.rs-video .animate-border .video-vemo-icon:before {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.rs-video .animate-border .video-vemo-icon:after {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  opacity: 0;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.btn-whatsapp:after, .btn-whatsapp:before {
  border: 2px solid #30bf39 !important;
  width: 130px !important;
  height: 130px !important;
}

.sm-red-dot {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 12px;
  height: 12px;
  margin: 0 auto;
  background: red;
  transform: scale(0);
  border-radius: 50%;
  animation-name: notificationPoint;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

.quick-message {
  position: absolute;
  bottom: 4px;
  right: 88px;
  width: max-content;
  border-radius: 0;
  background: #393b39;
}

.line-up {
  opacity: 0;
  animation-name: anim-lineUp;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  animation-delay: 5s;
}

.quick-message p {
  line-height: 40px;
  font-size: 15px;
  padding: 4px 16px;
  height: 40px;
  position: relative;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}

.quick-message .seta-direita:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #393b39;
  position: absolute;
  bottom: 0px;
  right: -30px;
}

.mx-auto {
  margin: 0 auto;
}

#hover-message {
  display: none;
}

.whatsapp-fixed:hover #hover-message {
  display: block;
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(.5);
    opacity: 1;
    border-width: 3px
  }
  40% {
    opacity: .5;
    border-width: 2px
  }
  65% {
    border-width: 1px
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px
  }
}

@keyframes sm-shake-animation {
  0% {
    transform: rotate(0) scale(1) skew(0.017rad)
  }
  25% {
    transform: rotate(0) scale(1) skew(0.017rad)
  }
  35% {
    transform: rotate(-0.3rad) scale(1) skew(0.017rad)
  }
  45% {
    transform: rotate(0.3rad) scale(1) skew(0.017rad)
  }
  55% {
    transform: rotate(-0.3rad) scale(1) skew(0.017rad)
  }
  65% {
    transform: rotate(0.3rad) scale(1) skew(0.017rad)
  }
  75% {
    transform: rotate(0) scale(1) skew(0.017rad)
  }
  100% {
    transform: rotate(0) scale(1) skew(0.017rad)
  }
}

@keyframes notificationPoint {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

@keyframes anim-lineUp {
  from {
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.owl-carousel .owl-item {
}

.owl-carousel .slide, .owl-carousel {
  display: block !important;
}

.flex-sd {
  flex-wrap: wrap;

  .item {
    max-width: 21.68rem;
    width: 100%;
  }
}

.owl-theme .owl-dots .owl-dot span {
  width: 7rem;
  border-radius: 0px 16px 0px 0px;
  opacity: 0.25;
  background: var(--Gelo, #E6EFFF);
  transition: all ease-in-out 300ms;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  height: 1.25rem;
  opacity: 1;
  background-color: #00E220;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
  position: absolute;
  width: 100%;
  bottom: 0.5rem;
}

.checkbox {
  position: relative;

  input:checked + span {
    width: 1rem;
    height: 1rem;
    background-image: url(../images/check-box.svg);

    background-size: cover;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}