//FRAMEWORK
@import "framework/reset";
@import "framework/flex-box";
@import "framework/margins";
@import "framework/typo";
@import "framework/positions";
@import "framework/images";

//CUSTOM
@import "custom/colors";
@import "custom/container";
@import "custom/buttons";
@import "custom/menus";
@import "custom/aspects";
@import "custom/effects";
@import "custom/forms";
@import "custom/all";
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'owl.carousel/dist/assets/owl.theme.default.min.css';

//TABLET
@import "hd";

//MOBILE
@import "mobile";
html, * {
  font-family: Montserrat, sans-serif;
}
.whatsapp-fixed {
  display: none !important;
}

@media (max-width: 1420px) {
  .row-form input[type=email], .row-form input[type=tel], .row-form input[type=text], .row-form select, textarea {
    font-size: 14px !important;
  }

  .button.tag, .button.small, .button.medium {
    font-size: 14px !important;
  }
}

.fs-12, .fs-14, .fs-16,  .fs-context-12, .fs-context-14, .fs-context-16 {
  font-size: 14px !important;
}