.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.max-100 {
  max-width: 100%;
}

.max-h-100vh {
  max-height: calc(100vh - 5rem);
}

.abs {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}


.right-0 {
  right: 0px;
}

img {
  display: block;
}