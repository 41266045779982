.appearing-1 {
  margin-top: 3.12rem;
  position: absolute;
  top: 0;
  right: 0px;
  img {
    max-width: 15.5rem;
    height: auto;
    width: 100%;
  }
}
.appearing-2 {
  position: absolute;
  bottom: 0;
  left: 0px;
  img {
    max-width: 15.5rem;
    height: auto;
    width: 100%;
  }

}
.appearing-4 {
  position: absolute;
  top:4.25rem;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-5 {
  position: absolute;
  bottom: 0;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-6 {
  position: absolute;
  top: 0;
  right: 0px;
  max-width: 33.6rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-7 {
  position: absolute;
  bottom: 0;
  left: 0px;
  max-width: 36rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-8 {
  position: absolute;
  top: 0;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-9 {
  position: absolute;
  bottom: 0;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-10 {
  position: absolute;
  top: 5rem;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-11 {
  overflow: hidden;
  position: absolute;
  bottom: -5rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-12 {
  overflow: hidden;
  position: absolute;
  top: -5rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-13 {
  overflow: hidden;
  position: absolute;
  top:0rem;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}


.appearing-14 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}


.appearing-15 {
  overflow: hidden;
  position: absolute;
  top:4.6rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-16 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  left: 1.125rem;
  max-width: 18rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-17 {
  overflow: hidden;
  position: absolute;
  top:0px;
  left: 0rem;
  max-width: 8.0625rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-18 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-19 {
  overflow: hidden;
  position: absolute;
  top:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-20 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-21 {
  overflow: hidden;
  position: absolute;
  top:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-22 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-23 {
  overflow: hidden;
  position: absolute;
  top:0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-24 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-25 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-27 {
  overflow: hidden;
  position: absolute;
  top:0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-28 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-29 {
  overflow: hidden;
  position: absolute;
  bottom:0px;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-blog-1 {
  overflow: hidden;
  position: absolute;
  bottom: 3rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-blog-2 {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-width: 10.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-blog-3 {
  overflow: hidden;
  position: absolute;
  top: 4.5rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-blog-4 {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}



.appearing-single-blog-1 {
  overflow: hidden;
  position: absolute;
  top: -5rem;
  right: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.appearing-single-blog-2 {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-width: 15.5rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1310px) {
  [class^="appearing-"] {
    display: none !important;
  }
}


