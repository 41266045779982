.menu-mobile {
  display: none;
}

@media (max-width: 768px) {
  .object-cover {
    width: 100%;
  }

  .menu-mobile {
    display: block;

    svg {
      max-height: 3.15rem;
      width: auto;

      rect {
        transition: width 300ms ease-in-out;
      }
    }

    &.act {
      #line-1 {
        width: 12px;
      }

      #line-2 {
        width: 24px;
      }

      #line-3 {
        width: 36px;
      }
    }
  }
  .topo-slider:before {
    display: none !important;
  }

  img {
    max-width: 100% !important;
  }
  .flex-direction-column-mobile {
    flex-direction: column !important;
    display: flex !important;
  }

  .flex-start-mobile {
    justify-content: flex-start !important;
  }

  .topo-slider {
    min-height: calc(100vh - 5rem);

    img {
      min-height: calc(100vh - 5rem) !important;
    }
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .thumbnail {
    max-width: 100%;
  }

  .overflow-mobile-auto {
    overflow: auto;
    min-width: calc(100% + 40px);
    position: relative;
    left: -20px;
    padding-left: 20px;

    > * {
      &:after {
        content: "";
        min-width: 1px;
        display: block;
      }
    }

    .grid-col-4, .grid-col-3 {
      display: flex !important;
    }

    > * > * {
      max-width: 75vw;
      width: 100%;
      min-width: 75vw;
    }
  }

  body {
    section {
      overflow-x: hidden;
      max-width: 100vw !important;
    }
  }

  .cards-horizontais .item:nth-child(2n) .context {
    padding-left: 2.5rem;
  }

  .disable-animation-mobile {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  [data-aos-disable-mobile] {
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }

  .flex-direction-column-mobile-reverse {
    flex-direction: column-reverse !important
  }

  .pr-mb-0 {
    padding-right: 0px !important;
  }
  .d-mb-none {
    display: none !important;
  }

  .min-mb-auto {
    min-width: auto;
  }

  .category + .category {
    padding-top: 0px !important;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .align-items-mb-start {
    align-items: start !important;
  }

  .max-336 {
    max-width: 100%;
  }

  .align-items-mb-center {
    align-items: center !important;
  }

  .lines-2, .lines-3 {
    -webkit-line-clamp: 5;
  }


  .py-72.background-color + .py-72.background-color {
    padding-top: 32px;
  }

  header .menu-container {
    position: fixed;
    left: -100vw;
    top: 5rem;
    width: 100%;
    height: calc(100vh - 5rem);
    overflow: auto;
    transition: all ease-in-out 300ms;
    background-color: rgba(0, 10, 40, .75);
    backdrop-filter: blur(0px);

    .nav-menu {
      flex-direction: column;
      justify-content: center;
      padding: 32px;
    }

    a {
      font-size: 16px !important;
    }

    &.act {
      backdrop-filter: blur(4px);
      left: 0px;
    }
  }

  .top-video .video-full, .top-video {
    height: auto;
    aspect-ratio: 4/4;
  }

  .top-video .play svg {
    max-width: 5rem;
    height: auto;
  }

  .top-video .play {
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
  }

  .flex-col-3.gap-24 .col-flex {
    max-width: 100%;
  }

  .valores .item {
    max-width: 65vw;
    min-width: 65vw;
  }

  .nossos-valores + .lideres {
    padding-top: 0px !important;
  }

  .sm-red-dot {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
  }

  .btn-whatsapp:after, .btn-whatsapp:before {
    width: 100px !important;
    height: 100px !important;
  }

  .quick-message {
    display: none !important;
  }

  .whatsapp-fixed {
    bottom: 18px;
    right: 18px;
  }

  .whatsapp-fixed svg {
    max-width: 28px;
    height: auto;
  }

  .btn-whatsapp {
    width: 48px !important;
    height: 48px !important;
  }
}