.text-white {
  * {
    color: inherit;
  }
}

strong {
  font-weight: bold;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
  &.child {
    p {
      font-weight: 300;
    }
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

/* ALIGN */

.text-center {
  text-align: center;

  &.child {
    * {
      text-align: inherit;
    }
  }
}

.text-decoration-none {
  text-decoration: none;
}

.text-left {
  text-align: left;

  &.child {
    * {
      text-align: inherit;
    }
  }
}

.text-right {
  text-align: right;

  &.child {
    * {
      text-align: inherit;
    }
  }
}

.inline-block {
  display: inline-block;
}

.lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.lines-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}