.container {
  max-width: 1437px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}




@media (max-width: 1818px) {
  html {
    font-size: 15px;
  }
  .container {
    max-width: 1680px;
  }

  .fs-12, .fs-14 {
    font-size: 12px !important;
  }

  .fs-context-14 *, .fs-context-12 *, .nav-menu a {
    font-size: 12px !important;
  }
}

@media (max-width: 1720px) {
  html {
    font-size: 14px;
  }
  .container {
    max-width: 1420px;
  }

  .fs-12, .fs-14 {
    font-size: 12px !important;
  }

  .fs-context-14 *, .fs-context-12 *, .nav-menu a {
    font-size: 12px !important;
  }
}


@media (max-width: 1420px) {
  html {
    font-size: 14px;
  }
  .container {
    max-width: 1040px;
  }

  .fs-12, .fs-14 {
    font-size: 14px !important;
  }

  .fs-context-14 *, .fs-context-12 *, .nav-menu a {
    font-size: 14px !important;
  }


}

