$sizes: 0, 2, 4, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96;

.pr-136 {
  padding-right: 136px;
}
@each $size in $sizes {
  .mt-#{$size} {
    margin-top: #{$size / 16}rem;
  }

  .bottom-#{$size} {
    bottom: #{$size / 16}rem;
  }


  .space-#{$size} {
    > * + * {
      margin-top: #{$size / 16}rem;
    }
  }

  .fs-context-#{$size} {
    * {
      font-size: #{$size / 16}rem;
    }
  }

  .mb-#{$size} {
    margin-bottom: #{$size / 16}rem;
  }

  .wpx-#{$size} {
    width: #{$size / 16}rem;
  }


  .fs-#{$size} {
    font-size: #{$size / 16}rem;
    * {
      font-size: inherit;
    }
  }

  .border-radius-#{$size} {
    border-radius: #{$size / 16}rem;
  }

  .px-#{$size} {
    padding-left: #{$size / 16}rem;
    padding-right: #{$size / 16}rem;;
  }

  .py-#{$size} {
    padding-top: #{$size / 16}rem;
    padding-bottom: #{$size / 16}rem;;
  }

  .pb-#{$size} {
    padding-bottom: #{$size / 16}rem !important;
  }

  .pr-#{$size} {
    padding-bottom: #{$size / 16}rem !important;
  }

  .gap-#{$size} {
    gap: #{$size / 16}rem;
  }

  .gap-y-#{$size} {
    row-gap: #{$size / 16}rem;
  }

  .lh-#{$size} {
    line-height: #{$size / 16}rem;
  }

  .text-#{$size} {
    * + * {
      margin-top: #{$size / 16}rem;
    }
  }
}


@media (max-width: 768px) {
  @each $size in $sizes {
    .mt-mb-#{$size} {
      margin-top: #{$size / 16}rem;
    }

    .bottom-mb-#{$size} {
      bottom: #{$size / 16}rem;
    }


    .space-mb-#{$size} {
      > * + * {
        margin-top: #{$size / 16}rem;
      }
    }

    .fs-context-mb-#{$size} {
      * {
        font-size: #{$size / 16}rem;
      }
    }

    .mb-mb-#{$size} {
      margin-bottom: #{$size / 16}rem;
    }

    .wpx-mb-#{$size} {
      width: #{$size / 16}rem;
    }


    .fs-mb-#{$size} {
      font-size: #{$size / 16}rem;
    }

    .border-radius-mb-#{$size} {
      border-radius: #{$size / 16}rem;
    }

    .px-mb-#{$size} {
      padding-left: #{$size / 16}rem !important;
      padding-right: #{$size / 16}rem !important;
    }

    .py-mb-#{$size} {
      padding-top: #{$size / 16}rem !important;
      padding-bottom: #{$size / 16}rem !important;
    }

    .pb-mb-#{$size} {
      padding-bottom: #{$size / 16}rem !important;
    }

    .pr-mb-#{$size} {
      padding-bottom: #{$size / 16}rem !important;
    }

    .gap-mb-#{$size} {
      gap: #{$size / 16}rem !important;
    }

    .gap-y-mb-#{$size} {
      row-gap: #{$size / 16}rem !important;
    }

    .lh-mb-#{$size} {
      line-height: #{$size / 16}rem;
    }

    .text-mb-#{$size} {
      * + * {
        margin-top: #{$size / 16}rem;
      }
    }
  }
}
