.aspect-464-330 {
  aspect-ratio: 464 / 330;
}

.aspect-469-320 {
  aspect-ratio: 469 / 320;
}

.aspect-469-340 {
  aspect-ratio: 469 / 340;
}

.aspect-438-290 {
  aspect-ratio: 438 / 290;
}

.aspect-531-520 {
  aspect-ratio: 531 / 520;
}

.aspect-276-240 {
  aspect-ratio: 276 / 240;
}

.aspect-580-480 {
  aspect-ratio: 580 / 480;
}

.aspect-383-250 {
  aspect-ratio: 383 / 250;
}

.aspect-336-240 {
  aspect-ratio: 336 / 240;
}



.min-h-489 {
  min-height: 30rem;
}

.fit-content {
  min-width: fit-content;
}